<script>
  import { get, call } from 'vuex-pathify'
  import ProductCardGrid from '@/components/marketplace/ProductCardGrid'
  import ProductTile from '@/components/product/ProductTile'
  import EssentialsProduct from '@/components/essentials/EssentialsProduct'
  import screen from '@/helpers/screen'

  export default {
    components: {
      ProductCardGrid,
      ProductTile,
      EssentialsProduct,
    },
    props: {
      query: {
        type: String,
        default: '',
      },
      sort: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        chosenProduct: null,
      }
    },
    computed: {
      ...get('essentials', ['searchProducts']),
      ...get('essentialsCart', ['isMaxInCart']),
      screen,
      productsPerRow() {
        if (this.screen.lg) return 5
        else if (this.screen.md) return 4
        return 2
      },
    },
    watch: {
      query() {
        this.search({ query: this.query, sort: this.sort })
      },
      sort() {
        this.search({ query: this.query, sort: this.sort })
      },
      chosenProduct() {
        // disable scrolling when looking at a product
        document.documentElement.style.overflow = this.chosenProduct ? 'hidden' : 'auto'
      },
    },
    created() {
      if (this.query) {
        this.search({ query: this.query, sort: this.sort })
      }
    },
    methods: {
      ...call('essentialsCart', ['addToCart']),
      ...call('essentials', ['search']),
    },
  }
</script>

<template>
  <div>
    <BaseTransitionView v-if="chosenProduct" appear>
      <EssentialsProduct
        :product="chosenProduct"
        :max-quantity-for-cart="10"
        :is-add-to-cart-enabled="true"
        @addToCart="addToCart"
        @closeModal="chosenProduct = null"
      />
    </BaseTransitionView>
    <div class="mb-40 md:px-10">
      <ProductCardGrid
        v-if="searchProducts.length"
        :columns="productsPerRow"
        class="pt-4 pb-10 px-2 md:px-0"
      >
        <ProductTile
          v-for="product in searchProducts"
          :key="product.id"
          :product="product"
          type="essentials"
          :is-max-in-cart="isMaxInCart(product.defaultVariant.id)"
          @click="chosenProduct = product"
        />
      </ProductCardGrid>

      <div v-else class="h-50 flex justify-center items-center">
        No products match this search.
      </div>
    </div>
  </div>
</template>
