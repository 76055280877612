<script>
  // TODO: this is a modified copy of packages/spa/src/views/PDP.vue
  // TOOD: that view should use this component
  // TODO: duplicates packages/spa/src/components/product/ProductTile.vue

  import { sync } from 'vuex-pathify'
  import trapFocus from '@/helpers/trapFocus'
  import screen from '@/helpers/screen'

  export default {
    props: {
      product: {
        type: Object,
        required: true,
      },
      maxQuantityForCart: {
        type: Number,
        required: true,
      },
      isAddToCartEnabled: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        quantity: 1,
        productContainerRef: null,
        productDescriptionProperties: [
          {
            property: 'instructions',
            title: 'Details & Care',
          },
          {
            property: 'specs',
            title: 'Ingredients & Materials',
          },
          {
            property: 'disclaimer',
            title: 'Good to Know',
          },
        ],
        addedTimeout: null,
        addingToCartOrWaitlist: false,
        addedToCartOrWaitlist: false,
      }
    },
    computed: {
      ...sync('general', ['preventNavigation']),
      screen,
      essentialsTags() {
        return (
          this.product?.essentialsTags?.reduce((allTags, tag) => {
            tag = tag.replace(/-/g, ' ')

            // ignore empties
            if (tag) {
              allTags.push(tag)
            }

            return allTags
          }, []) ?? []
        )
      },
      defaultVariant() {
        if (!this.product) {
          return undefined
        }
        // If the defaultVariant is out of stock, return the first variant that isn't
        const defaultVariant = this.product.defaultVariant
        if (!defaultVariant.hasUnlimitedStock && defaultVariant.stock === 0) {
          const firstVariantWithStock = this.product.variants.find((variant) => {
            return variant.hasUnlimitedStock || variant.stock > 0
          })
          if (firstVariantWithStock) {
            return firstVariantWithStock
          }
        }
        return defaultVariant
      },
      selectedVariant() {
        return this.defaultVariant
      },
      inStock() {
        return (
          this.selectedVariant &&
          (this.selectedVariant.hasUnlimitedStock || this.selectedVariant.stock > 0)
        )
      },
      quantitySelectionOptions() {
        // Use the Variant's maxQuantity if it's set, otherwise default to 10 as the
        // ceiling. Cap the ceiling at 10 for any instance where maxQuantity is
        // greater than that.
        const ceiling = Math.min(this.selectedVariant.maxQuantity || 10, 10)

        const max =
          this.selectedVariant &&
          this.selectedVariant.stock < ceiling &&
          !this.selectedVariant.hasUnlimitedStock
            ? this.selectedVariant.stock
            : ceiling
        if (max <= 0) {
          return []
        }
        return [...Array(Math.min(max, this.maxQuantityForCart)).keys()].map((i) => i + 1)
      },
      percentOff() {
        if (!this.product) {
          return 0
        }
        const discountAmount = this.product.retailValue - this.selectedVariant.price
        return Math.round((discountAmount / this.product.retailValue) * 100)
      },
    },
    created() {
      window.addEventListener('keydown', this.handleKeyDown)
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyDown)
    },
    methods: {
      handleKeyDown($event) {
        // If the escape key was pressed, close the modal
        if ($event.keyCode === 27) {
          this.$emit('closeModal')
          return
        }
        // Trap the focus inside the modal
        trapFocus($event, this.productContainerRef)
      },
      addToCart() {
        this.preventNavigation = true
        this.addingToCartOrWaitlist = true
        clearTimeout(this.addedTimeout)
        try {
          this.$emit('addToCart', { variantId: this.selectedVariant.id, quantity: this.quantity })
          this.addingToCartOrWaitlist = false
          this.addedToCartOrWaitlist = true
          this.addedTimeout = setTimeout(() => {
            this.addedToCartOrWaitlist = false
          }, 5000)
        } finally {
          this.addingToCartOrWaitlist = false
        }
      },
      addToWaitlist() {
        this.preventNavigation = true
        clearTimeout(this.addedTimeout)
        this.$emit('addToWaitlist', { productId: this.product.id })
        this.addedToCartOrWaitlist = true
        this.addedTimeout = setTimeout(() => {
          this.addedToCartOrWaitlist = false
        }, 5000)
      },
      addHandler() {
        return this.isSoldOut ? this.addToWaitlist() : this.addToCart()
      },
    },
  }
</script>

<template>
  <section class="z-40 md:z-60 fixed inset-0 border-t border-gray-active">
    <div class="absolute inset-0 -ml-16 opacity-80 bg-true-black" />
    <div class="absolute inset-0 mt-17 overflow-y-auto parent-scrollbar">
      <div class="flex min-h-full">
        <div
          v-ref="productContainerRef"
          class="product-container ml-auto w-full md:w-4/5 max-w-300 pb-40 sm:pb-20 overflow-hidden bg-dawn lg:pr-8"
          @scroll="$event.target.scrollLeft = 0"
        >
          <!-- Back arrow -->
          <button
            type="button"
            :class="`z-80 fixed ${
              screen.md ? '-ml-16 border-1 bg-dawn' : ''
            } w-16 h-16 flex items-center justify-center border-black transition duration-200`"
            aria-label="Back"
            @click="$emit('closeModal')"
          >
            <BaseIcon :size="8">
              <IconArrowLeft />
            </BaseIcon>
          </button>

          <!-- Image gallery and product details -->
          <div class="flex flex-col lg:flex-row lg:overflow-y-auto product-details-scrollbar">
            <div class="lg:w-1/2">
              <!-- Mobile favorite icon -->
              <!-- <div
                v-if="!screen.lg"
                class="z-90 absolute right-0 top-0 flex justify-end items-center h-14 px-6"
              >
                <BaseTooltip>
                  <FavoriteToggle
                    :label="product.title"
                    :is-favorited="isFavorited"
                    @change="setFavorited"
                  />
                  <template v-slot:tooltip>
                    {{ isFavorited ? 'Remove from favorites' : 'Add to favorites' }}
                  </template>
                </BaseTooltip>
              </div> -->

              <!-- Product image slider + gallery -->
              <div v-if="screen.lg">
                <div v-for="(image, imageKey) in product.images" :key="imageKey">
                  <BaseImage
                    :src="image"
                    :alt="product.title"
                    class="mb-4"
                    responsive="md"
                    sizes="45vw"
                  />
                </div>
              </div>
              <BaseSliderWithGallery
                v-else
                :images="product.images"
                :alt="product.title"
                inner-gallery
              />
            </div>

            <!-- Product details -->
            <div class="lg:w-1/2 pt-8">
              <BaseContainer class="lg:px-15">
                <!-- Desktop favorite icon -->
                <!-- <div v-if="screen.lg" class="z-10 mb-4 h-16">
                  <BaseTooltip>
                    <FavoriteToggle
                      :label="product.title"
                      :is-favorited="isFavorited"
                      @change="setFavorited"
                    />
                    <template v-slot:tooltip>
                      {{ isFavorited ? 'Remove from favorites' : 'Add to favorites' }}
                    </template>
                  </BaseTooltip>
                </div> -->

                <!-- Brand, title, price -->
                <div
                  class="mb-1 font-bold md:font-semibold text-xs md:text-base leading-snug uppercase tracking-wide"
                >
                  {{ product.brand }}
                </div>
                <h2 class="md:text-xl leading-tight">
                  {{ product.title }}
                </h2>
                <div class="mt-2 md:mt-6 flex items-center">
                  <span class="mr-2 font-semibold text-base md:text-lg">
                    {{ $formatPrice(selectedVariant.price) }}
                  </span>
                  <s
                    v-if="product.retailValue && product.retailValue > selectedVariant.price"
                    class="mr-4 text-base md:text-lg"
                  >
                    {{ $formatPrice(product.retailValue) }}
                  </s>
                  <span
                    v-if="percentOff > 0"
                    class="bg-black text-white px-1 text-center text-xs md:text-2xs md:font-semibold tracking-wide"
                  >
                    {{ percentOff }}% OFF
                  </span>
                </div>

                <div class="flex flex-col-reverse md:flex-col">
                  <!-- Product description -->
                  <BaseRichText class="mt-6" v-html="product.description" />

                  <!-- Qty + add to cart/waitlist button -->
                  <div
                    class="z-20 fixed inset-x-0 bottom-0 sm:static sm:inset-auto sm:mt-9 px-6 py-4 sm:p-0 border-t border-gray-active sm:border-0 bg-dawn"
                  >
                    <div class="flex">
                      <div class="mr-4 w-24 flex-shrink-0">
                        <BaseInput
                          v-model="quantity"
                          type="select"
                          :options="quantitySelectionOptions"
                          :disabled="!inStock"
                        >
                          Qty
                        </BaseInput>
                      </div>
                      <div class="flex-grow">
                        <BaseButton
                          :disabled="inStock ? !isAddToCartEnabled : false"
                          @click="addHandler"
                        >
                          <span class="truncate">
                            <span v-if="addingToCartOrWaitlist">Adding...</span>
                            <span v-else-if="addedToCartOrWaitlist">Added!</span>
                            <span v-else>{{ inStock ? 'Add to cart' : 'Add to waitlist' }}</span>
                          </span>
                        </BaseButton>
                      </div>
                    </div>
                  </div>

                  <!-- Essentials tags -->
                  <div class="flex flex-wrap mt-8">
                    <div
                      v-for="(tag, tagIndex) in essentialsTags"
                      :key="tagIndex"
                      class="border-black border-1 rounded-full px-2 py-2px mr-1 mb-1 capitalize text-2xs"
                    >
                      {{ tag }}
                      <BaseIcon :size="3" class="inline-block align-middle">
                        <IconCheckmarkThick />
                      </BaseIcon>
                    </div>
                  </div>
                </div>

                <!-- Product description -->
                <div class="mt-10 border-b-2 border-black">
                  <div
                    v-for="({ property, title }, propertyIndex) in productDescriptionProperties"
                    :key="propertyIndex"
                  >
                    <div v-if="product[property]" :class="`border-t-2 border-black`">
                      <BaseAccordion button-inner-classes="py-4">
                        <template v-slot:buttonLabel> {{ title }} </template>
                        <template v-slot>
                          <div class="pb-5">
                            <BaseRichText v-html="product[property]" />
                          </div>
                        </template>
                      </BaseAccordion>
                    </div>
                  </div>
                </div>
              </BaseContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss">
  /* purgecss start ignore */
  .parent-scrollbar {
    /* width */
    &::-webkit-scrollbar {
      @apply w-0;
    }
  }

  .product-details-scrollbar {
    /* width */
    &::-webkit-scrollbar {
      @apply w-1;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      @apply my-5 bg-gray-opacity68 rounded-full;

      @screen md {
        @apply mx-10;
      }

      @screen xl {
        @apply mx-20;
      }
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      @apply bg-black rounded-full;
    }
  }
</style>
